<template>
    <div class="text-center">
        <img style="margin-bottom: 20px;margin-top: 20px; height: 50px;" :src="require('@/assets/trophy.png')" height="100"
            alt="">
        <h1><span class="secondary" style="font-size: 20px;">{{ getTitle("HERE_ARE_YOUR") }} </span> <span class="primary"
                style="font-size: 20px;">{{ getTitle("RESULTS")
                }}</span><span class="secondary">{{ getTitle("!") }}</span></h1>
        <p v-if="data['email'] != null" style="font-size:15px;font-style:italic;">{{
            getTitle("THIS_CERTIFICATE_HAS_BEEN_SENT_TO_YOUR_EMAIL") }} '{{ data["email"] }}'</p>
    </div>
    <div ref="printcontent"
        style="max-width: 55%;height: auto;margin-left:auto;margin-right:auto;margin-top:20px;position:relative; box-shadow: rgba(255, 245, 109, 0.1) 0px 7px 29px 0px; border-radius: 20px;">
        <img :src="require('@/assets/certificate.jpg')" alt="" style="max-width: 100%; height: auto;">
        <div style="position:absolute;top:48%;width:65%;">
            <p style="color: black !important;font-size:3vw !important;font-style:italic;">{{
                data["name"] }}
            </p>
        </div>
        <div style="position:absolute;top:50%;right:13%;">
            <p style="color: #FAB60D !important;font-size:2vw !important;font-style:italic;font-weight:bolder;">{{
                data["iq_score"] }}
            </p>
        </div>
        <div style="position:absolute;top:71%;width:93%;">
            <p style="color: rgb(51, 51, 51) !important;font-size:1vw !important;font-weight:bold;">{{ data["timestamp"] }}
            </p>
        </div>
    </div>

    <div class="container">
        <p style="margin-top: 10px; font-size:15px;font-style:italic;">{{
            getTitle("SHARE_YOUR_IQ_ACHIEVEMENT_WITH_THE_WORLD!") }}</p>
        <p style="margin-top: -10px; font-size:15px;font-style:italic;">{{
            getTitle("POST_YOUR_RESULTS_ON_SOCIAL_MEDIA_AND_INSPIRE_OTHERS_TO_INVEST_IN_THEIR_POTENTIAL_TOO") }} 🌟</p>
        <p class="secondary" style="margin-top: -10px; font-size:30px; font-weight: bolder; font-style:italic;">{{
            getTitle("SHARE!") }}</p>
        <div class="row">
            <div class="col-sm" style="margin-bottom: 20px; position:relative;">
                <span @click="shareOnFacebook()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                        class="bi bi-facebook text-light" viewBox="0 0 16 16">
                        <path
                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                </span>&nbsp; &nbsp;
                <ShareNetwork network="twitter" :url=links title="Here's my IQ Test results!" description="" quote=""
                    hashtags="iqtest" style="margin-right: 20px;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="55" height="55" version="1.1"
                        style="color:white;">
                        <path
                            d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                            fill="#ffffff"></path>
                    </svg>
                </ShareNetwork>
                <ShareNetwork network="linkedIn" :url=links title="Here's my IQ Test results!" description="" quote=""
                    hashtags="iqtest" style="margin-right: 20px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                        class="bi bi-linkedin text-light" viewBox="0 0 16 16">
                        <path
                            d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                    </svg>
                </ShareNetwork>
                <svg @click="copyURL()" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                    class="bi bi-clipboard-check" viewBox="0 0 16 16" style="cursor: pointer;">
                    <path fill-rule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path
                        d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                </svg>&nbsp;
                <svg @click="downloadImage" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="45" height="50"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M21 15l-9 9-9-9M12 2v21"></path>
                </svg>


            </div>
        </div>
        <button type="button" class="custom-button mt-3 mb-3" style="background-color: #fab60d !important"
            @click="redirectToTryAgain">
            {{ getTitle("TRY_AGAIN") }}
        </button>
    </div>
</template>
<script>
import englishLanguage from '../../lang/English.json'
import romanianLanguage from '../../lang/Romanian.json';

import html2canvas from 'html2canvas';
import '../../css/styles.css';
import axios from 'axios';

export default {
    mounted() {
        this.getResults();
    },
    created() {
        this.setCurrentLanguage();
    },
    watch: {
        listener() {
            let delayInMilliseconds = 2000; //1 second
            setTimeout(function () {
                this.saveImage();
            }.bind(this), delayInMilliseconds);
        }
    },
    data() {
        return {
            doneAnswering: "Start", // Start, Answer, Done, Loading, Sent
            percentage: 0,
            iqScore: 0,
            email: "",
            name: "",
            randomQuestions: [],
            commentsList: [],
            userAnswers: [],
            correctAnswers: 0,
            loaderValue: 0,
            data: {},
            links: "https://iqlivetest.com/result.php?id=#", // do not change to demo.
            listener: "",
        }
    },
    methods: {
        setCurrentLanguage() {
            const path = this.$route.path;
            this.currentLanguage = path.includes("/ro") ? romanianLanguage : englishLanguage;
        },

        getTitle(key) {
            return this.currentLanguage[key];
        },

        async getResults() {
            this.links = "https://iqlivetest.com/result.php?id=" + this.$route.params.id;  // do not change to demo.
            axios
                .get('https://backend.iqlivetest.com/api/result/' + this.$route.params.id)
                .then((res) => {
                    if (res["data"][0] != null) {
                        this.invalid = false;
                        this.data = res["data"][0];
                        this.listener = "test";
                        if (this.data["email_sent"] == 0) {
                            this.sendEmail();
                        }
                    } else {
                        this.invalid = true;
                    }
                    this.doneLoading = true;
                })
                .catch(() => {
                },);
        },
        async sendEmail() {
            if (!this.data["email_sent"]) {

                // Determine the email path based on the language
                const emailPath = this.$route.path.includes('/ro') ? 'send_mail_ro' : 'send_mail';

                axios.post(`https://demo.iqlivetest.com/email_sender/public/api/${emailPath}`, { //Can change to http://localhost:8000 for testing
                    email: this.data["email"],
                    name: this.data["name"],
                    iq_score: this.data["iq_score"],
                    correct_answers: this.data["correct_answers"],
                    total_items: this.data["total_items"],
                    id: this.data["sms_unique_id"],
                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Credentials': 'true',
                            'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                            'Access-Control-Allow-Headers':
                                'Origin, X-Requested-With, Content-Type, Accept, Authorization',
                        },
                    }
                )
                    .then(() => {
                        this.updateEmailSent();
                    })
                    .catch(() => {
                        this.updateEmailSent();
                    });
            }
        },
        updateEmailSent() {
            axios
                .get('https://backend.iqlivetest.com/api/result/update/' + this.$route.params.id)
                .then(() => {
                })
                .catch(() => {
                },);

            // For postback Romania and English
            const postbackEndpoint = this.$route.path.includes('/ro') ? 'postback_ro' : 'postback_en';
            const postbackURL = 'https://backend.iqlivetest.com/api/' + postbackEndpoint + '/' + this.data["clickid"];

            axios
                .get(postbackURL)
                .then(() => {
                    console.log("Postback Successful");
                })
                .catch(() => {
                    console.log("Postback Error");
                });
        },
        shareOnFacebook() {
            // URL to be shared
            const urlToShare = this.links;

            // Hashtag to be used in the Facebook share
            const hashtag = '#iqtest';

            // Form the Facebook sharing URL with the intent method and hashtag
            const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}&hashtag=${encodeURIComponent(hashtag)}`;

            // Open the Facebook share dialog in a new window
            window.open(facebookShareURL, '_blank');
        },
        async saveImage() {
            const el = this.$refs.printcontent;

            const options = {
                type: "dataURL",
            };
            const printCanvas = await html2canvas(el, options);

            const link = document.createElement("a");
            link.setAttribute("download", this.$route.params.id + '.png');
            link.setAttribute(
                "href",
                printCanvas
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream")
            );
            let myDataObj = {
                base64data: link,
                title: this.$route.params.id,
            }
            let formData = new FormData();
            for (let key in myDataObj) {
                formData.append(key, myDataObj[key])
            }
            axios.post('https://iqlivetest.com/upload.php', formData,  // do not change to demo.
            )
                .then(() => {
                })
                .catch(() => {
                },);
        },
        async copyURL() {
            try {
                await navigator.clipboard.writeText("https://iqlivetest.com/result.php?id=" + this.$route.params.id);
                window.alert("Link copied to clipboard.")
            } catch ($e) {
                // this.showNotification("Error please try again.")
            }
        },
        downloadImage() {
            const el = this.$refs.printcontent;

            const options = {
                type: "dataURL",
            };
            html2canvas(el, options).then((canvas) => {
                const link = document.createElement("a");
                link.setAttribute("download", "IQ Test Certificate.png");
                link.setAttribute(
                    "href",
                    canvas
                        .toDataURL("image/png")
                        .replace("image/png", "image/octet-stream")
                );
                link.click();
            });
        },
        redirectToTryAgain() {
            // Check if the current path includes '/ro'
            const isRomanian = this.$route.path.includes('/ro');

            const tryAgainPath = isRomanian ? 'ro' : '';
            const redirectUrl = `https://demo.iqlivetest.com/${tryAgainPath}`;

            // Perform the redirection
            window.location.href = redirectUrl;
        },
    },
};
</script>
  
  