<style scoped>
.countdown-circle {
  position: relative;
}

.countdown-timer {
  position: absolute;
  top: 80px;
  right: -20px;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 50px;
}

.border-circle {
  width: 200%;
  height: 200%;
  border-radius: 50%;
  border: 4px solid transparent;
  /* Start with a transparent border */
  position: absolute;
  animation: rotateBorder 1s linear infinite;

}

@keyframes rotateBorder {
  to {
    transform: rotate(360deg);
    border-color: #FAB60D;
    /* Yellow color when fully rotated */
  }
}

.countdown-text {
  font-size: 30px;
  font-weight: bold;
  z-index: 1;
}
</style>
<template>
  <span>
    <div class="position-absolute" style="height: 100%; width: 100%">
      <div class="text-center mx-auto">
        <img class="mt-5" :src="require('@/assets/logo1.png')" alt="" style="height: auto; margin:auto; max-width: 100%;" />
      </div>
    </div>
    <div class="position-relative">
      <div v-if="doneAnswering == 'Start'" class="container-sm p-5 center customBody">
        <h1>
          <span class="secondary">{{ getTitle("CHALLENGE") }}&nbsp;</span>
          <span class="primary">{{ getTitle("YOURSELF") }}</span> <br>
          <span class="secondary">{{ getTitle("AND_BEAT_THE_ODDS") }}</span>
        </h1>
        <p style="background-color: #FAB60D; border-radius: 5px;" class="p-1 mt-3">{{ getTitle("TEST_YOUR_IQ") }}</p>
        <p class="mt-3">
          {{ getTitle("JOIN_THE_RANKS_OF_THE_TOP") }} <span class="primary">{{ getTitle("10%") }}</span> {{
            getTitle("OF_THE_WORLD'S_BRIGHTEST_MINDS_BY_ANSWERING") }}
          <span class="custom-pill">{{ getTitle("TWO-THIRDS") }}</span> {{
            getTitle("OF_OUR_CHALLENGING_QUESTIONS_CORRECTLY!") }}
        </p>
        <p class="paragraph" style="margin-top: 40px">
          {{ getTitle("DON'T_MISS_YOUR_CHANCE_TO_PROVE_YOUR") }}
          <span class="primary">{{ getTitle("MENTAL_PROWESS") }}</span> {{
            getTitle("AND_JOIN_THE_EXCLUSIVE_CLUB_OF_INDIVIDUALS_WHO_ARE") }}
          <span class="custom-pill">{{ getTitle("SMARTER") }}</span> {{ getTitle("THAN_MOST_OF_WORLD'S_POPULATION!") }}
        </p>
        <h3 style="margin-top: 40px">{{ getTitle("PUT_YOUR_SKILLS_TO_THE_TEST!") }}</h3>
        <p class="paragraph">{{ getTitle('ENTER_YOUR_NAME_BELOW_AND_HIT_START_TO_BEGIN') }}</p>
        <div class="d-flex justify-content-center">
          <div class="" style="width: 50%">
            <div class="form-group">
              <input type="name" v-model="name" name="user_name" style="text-align: center" class="form-control"
                id="exampleInputEmail1" aria-describedby="name" />
              <p style="font-size: 12px;">{{ getTitle("ENTER_YOUR_NAME") }}</p>
            </div>
          </div>
        </div>
        <button @click="setDoneTest('Answer')" type="button" class="custom-button mt-5">
          {{ getTitle("START") }}
        </button>
      </div>

      <div v-if="doneAnswering == 'Loading'" class="container center customBody" style="margin-top: 300px">
        <div class="d-flex justify-content-center">
          <circle-progress :percent="loaderValue" :border-width="4" :border-bg-width="4" :is-gradient="true" :gradient="{
            angle: 100,
            startColor: '#16A637',
            stopColor: '#FAB60D',
          }" />
        </div>
        <h4 class="mt-3">Please wait...</h4>
      </div>

      <div v-if="doneAnswering == 'Done'" class="container center customBody">
        <br />
        <p style="background-color: #35a637; border-radius: 5px;" class="p-1 mt-3 mb-5">{{ getTitle("IMPRESSIVE!") }}</p>
        <p v-if="iqScore >= 70">
          {{ getTitle("YOUR_SCORE_IS_QUITE_REMARKABLE_FEW_PARTICIPANTS_HAVE") }}
          <span class="primary">{{ getTitle("ACHIEVED") }}</span> {{ getTitle("SUCH_AN_IMPRESSIVE_RESULT") }}
        </p>
        <p v-else>
          {{
            getTitle("YOUR_IQ_IS_READY_THE_RESULT_IS_BELOW_AVERAGE_DON'T_BE_UPSET_SOME_QUESTIONS_ARE_VERY_DIFFICULT_WOULD_YOU_LIKE_TO_TRY_AGAIN_WITH_SOME")
          }}
          <span class="primary">{{ getTitle("NEW_QUESTIONS") }}</span>
          {{ getTitle("?_SURELY_THIS_TIME_YOU_WILL_SUCCEED_BETTER") }}
        </p>
        <button v-if="iqScore <= 70" @click="reloadPage()" type="button" class="custom-button mt-3 mb-3"
          style="background-color: #fab60d !important">
          {{ getTitle("TRY_AGAIN") }}
        </button>
        <div class="d-flex justify-content-center">
          <div class="box">
            <p class="black">
              {{ getTitle("ENTER_YOUR_EMAIL_ADDRESS_AND_PRESS_THE_BUTTON_BELOW_TO_INSTANTLY") }}
              <span class="secondary">{{ getTitle("RECEIVE_YOUR_TEST_RESULT") }}</span> {{ getTitle("IN_YOUR_INBOX") }}
            </p>
          </div>
        </div>
        <div class="text-center">
          <section style="
              background-color: transparent;
              font-size: 15px !important;
              border-radius: 30px;
              margin-top: 30px;
            ">
            <div class="container my-2 py-2 text-dark">
              <div class="row d-flex justify-content-center">
                <div class="col-md-12 col-lg-10 col-xl-8" style="
                    background-color: white;
                    padding: 20px;
                    border-radius: 20px;
                    height: 300px;
                    overflow-y: scroll;
                  ">
                  <p>{{ commentsList.length }} Comments</p>
                  <div v-for="(data, index) in commentsList" :key="index" class="card mb-3">
                    <div class="card-body">
                      <div class="d-flex flex-start">
                        <img class="rounded-circle shadow-1-strong me-3" :src="require('@/assets/user.png')" alt="avatar"
                          width="40" height="40" />
                        <div class="w-100">
                          <h6 class="text-primary fw-bold mb-0" style="font-size: 12px; text-align: left">
                            {{ data["name"] }}
                          </h6>
                          <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-primary fw-bold mb-0" style="font-size: 12px; text-align: left">
                              <span class="text-dark" style="font-size: 10px">{{
                                data["comment"]
                              }}</span>
                            </h6>
                            <p class="mb-0" style="font-size: 10px !important">
                              {{ data["likes"] }} Likes
                            </p>
                          </div>
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex flex-row">
                              <i class="fas fa-star text-warning me-2"></i>
                              <i class="far fa-check-circle" style="color: #aaa"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-auto d-flex justify-content-center" style="width: 50%; margin-top: 20px">
              <div class="form-group mx-auto d-flex justify-content-center">
                <input type="email" v-model="email" name="user_email" style="text-align: center" class="form-control"
                  id="exampleInputEmail1" aria-describedby="name" />
              </div>
            </div>
            <p style="font-size:12px;">{{ getTitle("ENTER_YOUR_EMAIL") }}</p>
            <stripe-checkout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="lineItems"
              :success-url="successURL" :cancel-url="cancelURL" @loading="(v) => (loading = v)" />
            <button @click="submit()" value="send" class="custom-button mt-3 mb-1"
              style="background-color: #fab60d !important; font-weight: bolder">
              <span v-if="!submitLoading">{{ getTitle("SEND_TO_EMAIL") }}</span>
              <div v-else class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            </button>
            <p v-if="showEmailError" style="color: #fab60d">
              {{ getTitle("PLEASE_ENTER_YOUR_EMAIL") }}
            </p>
          </section>
        </div>
      </div>
      <div v-if="doneAnswering == 'Sent'" class="container center customBody" style="margin-top: 300px">
        <div class="text-center">
          <img :src="require('@/assets/delivered.png')" alt="" height="130" />
          <h2 class="mt-3">
            {{ getTitle("YOUR_IQ_TEST_RESULT_HAS_BEEN_SENT_TO_YOUR_EMAIL") }}
            <i>"{{ this.email }}"</i>.
          </h2>
        </div>
      </div>
    </div>
    <swiper class="mySwiper container center customBody pt-5" v-if="doneAnswering == 'Answer'" :allow-touch-move="false"
      :mousewheel="false" :keyboard="false" @swiper="onSwiper" :direction="'vertical'" :modules="modules">

      <div v-if="countdown > 0" class="countdown-timer">
        <div class="border-circle" :style="{ transform: `rotate(${360 - (countdown * 12)}deg)` }"></div>
        <div class="countdown-text">{{ countdown }}</div>
      </div>

      <swiper-slide class="p-5" v-for="(data, index) in randomQuestions" :key="index"
        style="display: block; position:relative; top: 50px;">
        <h3 unselectable="on"
          style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select: none; user-select: none;">{{
            data["elements"][0]["title"] }}</h3>
        <br />
        <div class="row justify-content-center">
          <div v-for="(answer, index2) in data['elements'][0]['choices']" :key="index2" class="col-md-6 mb-2">
            <button @click="handleSlideTo(index + 1, data['elements'][0], answer)" type="button" class="answers mt-3">
              {{ answer }}
            </button>
          </div>
        </div>
        <div class="progress" style="position:relative; top: 60px; height: 10px;">

          <div class="progress-bar" role="progressbar" style="background-color: #35a637; height: 10px;"
            :style="{ width: progressBarWidth }" aria-valuenow="" aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
        <p class="text-center" style="position:relative; top: 70px;">{{ index+1 }} out of 12 Questions</p>
      </swiper-slide>
    </swiper>
  </span>
</template>
<script>
//English Language
import englishLanguage from '../../lang/English.json';
import { comments } from "../../comments.js";
import { verbalComprehension, logicalReasoning, numericalAptitude, spatialIntelligence, } from "../../questions.js";

//Romanian Language
import romanianLanguage from '../../lang/Romanian.json';
import { ro_comments } from "../../ro_comments.js";
import { ro_verbalComprehension, ro_logicalReasoning, ro_numericalAptitude, ro_spatialIntelligence, } from "../../ro_questions.js";

import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../../css/styles.css";
// import required modules
import { Pagination } from "swiper";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";

export default {
  components: {
    Swiper,
    SwiperSlide,
    CircleProgress,
    StripeCheckout,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  created() {
    this.setCurrentLanguage();
    this.setQuestions();
  },
  mounted() {
    this.smsRandomId = Math.random().toString(36).slice(2, 500);
    if (this.$route.query.clickid != null) {
      this.clickid = this.$route.query.clickid;
    }
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      progressBarWidth: "0%",
      countdown: 45,
      countdownTimer: null,
      doneAnswering: "Start", // Start, Answer, Done, Loading, Sent
      percentage: 0,
      iqScore: 0,
      email: "",
      name: "",
      randomQuestions: [],
      commentsList: [],
      userAnswers: [],
      correctAnswers: 0,
      loaderValue: 0,
      emailSent: false,
      loading: false,
      showEmailError: false,
      englishLanguage,
      romanianLanguage,
      currentLanguage: null,
      lineItems: [
        {
          price: this.$route.path.startsWith("/ro")
            ? process.env.VUE_APP_STRIPE_PRICE_ID_RO
            : process.env.VUE_APP_STRIPE_PRICE_ID_EN,
          // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: "https://demo.iqlivetest.com/",
      cancelURL: "https://demo.iqlivetest.com/",
      clickid: "",
      submitLoading: false,
      smsRandomId: "",
    };
  },
  methods: {

    setCurrentLanguage() {
      const path = this.$route.path;
      this.currentLanguage = path === "/" ? englishLanguage : romanianLanguage;
    },
    getTitle(key) {
      return this.currentLanguage[key];
    },
    setQuestions() { // Set the questions based on the current language
      const language = this.$route.path.includes("/ro") ? "ro" : "";

      const getRandomQuestions = (categoryQuestions) => {
        const addedIndex = [];
        const randomQuestions = [];

        while (addedIndex.length < 3 && addedIndex.length < categoryQuestions.length) {
          const random = Math.floor(Math.random() * categoryQuestions.length);

          if (!addedIndex.includes(random)) {
            randomQuestions.push(categoryQuestions[random]);
            addedIndex.push(random);
          }
        }

        return randomQuestions;
      };

      const verbalComprehensionQuestions = language === "ro" ? ro_verbalComprehension : verbalComprehension;
      const logicalReasoningQuestions = language === "ro" ? ro_logicalReasoning : logicalReasoning;
      const numericalAptitudeQuestions = language === "ro" ? ro_numericalAptitude : numericalAptitude;
      const spatialIntelligenceQuestions = language === "ro" ? ro_spatialIntelligence : spatialIntelligence;

      this.randomQuestions.push(...getRandomQuestions(verbalComprehensionQuestions));
      this.randomQuestions.push(...getRandomQuestions(logicalReasoningQuestions));
      this.randomQuestions.push(...getRandomQuestions(numericalAptitudeQuestions));
      this.randomQuestions.push(...getRandomQuestions(spatialIntelligenceQuestions));

      const commentsList = language === "ro" ? ro_comments : comments;
      this.commentsList = commentsList
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    },

    addRandomQuestions(categoryQuestions, count) { // Add random questions to the list
      const addedIndex = [];
      while (addedIndex.length < count && addedIndex.length < categoryQuestions.length) {
        const random = Math.floor(Math.random() * categoryQuestions.length);
        this.randomQuestions.push(categoryQuestions[random]);
        addedIndex.push(random);
      }
    },
    setDoneTest(type) {
      if (this.name !== "") {
        this.doneAnswering = type;

        // Check if the type is 'Answer' and start the timer
        if (type === 'Answer') {
          // Set the initial countdown value
          this.countdown = 45;

          // Start the countdown timer
          this.countdownTimer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              // Time's up, automatically set to the wrong answer and proceed to the next question
              const index = this.userAnswers.length;
              const nextQuestion = this.randomQuestions[index];
              this.handleSlideTo(index + 1, nextQuestion['elements'][0], '');
            }
          }, 1000);
        }
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    handleSlideTo(index, question, userAnswer) { // Handle the slide to the next question
      this.progressBarWidth = ((index) / this.randomQuestions.length) * 100 + "%";
      let check = false;
      if (userAnswer == question["correctAnswer"]) {
        check = true;
      }
      this.userAnswers.push({
        question: question["title"],
        userAnswer: userAnswer,
        correctAnswer: question["correctAnswer"],
        check: check,
      });

      // Clear the existing countdown timer
      if (this.countdownTimer) {
        clearInterval(this.countdownTimer);
      }

      if (index >= this.randomQuestions.length) {
        this.setDoneTest("Loading");
        const count = this.userAnswers.filter((arr) => arr["check"]).length;
        this.correctAnswer = count;
        this.computeIQScore();
        this.loadLoader();
      } else {
        // Start a new countdown timer for the next question if it exists
        const nextQuestion = this.randomQuestions[index];
        if (nextQuestion && nextQuestion["elements"]) {
          this.countdown = 45;
          this.countdownTimer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              // Time's up, automatically set to the wrong answer and proceed to the next question
              this.handleSlideTo(index + 1, nextQuestion["elements"][0], "");
            }
          }, 1000);

          this.swiper.slideTo(index);
        } else {
          // No next question, proceed to the loading state or completion
          this.setDoneTest("Loading");
          const count = this.userAnswers.filter((arr) => arr["check"]).length;
          this.correctAnswer = count;
          this.computeIQScore();
          this.loadLoader();
        }
      }
    },
    computeIQScore() { // Compute the IQ Score based on the correct answers
      this.percentage = (
        (this.correctAnswer / this.randomQuestions.length) *
        100
      ).toFixed(2);
      if (this.percentage >= 0 && this.percentage <= 4) {
        this.iqScore = 55;
      } else if (this.percentage >= 5 && this.percentage <= 9) {
        this.iqScore = 63;
      } else if (this.percentage >= 10 && this.percentage <= 24) {
        this.iqScore = 75;
      } else if (this.percentage >= 25 && this.percentage <= 49) {
        this.iqScore = 87;
      } else if (this.percentage >= 50 && this.percentage <= 74) {
        this.iqScore = 99;
      } else if (this.percentage >= 75 && this.percentage <= 89) {
        this.iqScore = 103;
      } else if (this.percentage >= 90 && this.percentage <= 94) {
        this.iqScore = 112;
      } else if (this.percentage >= 95 && this.percentage <= 100) {
        this.iqScore = 125;
      }
    },
    async loadLoader() { 
      const timer = (ms) => new Promise((res) => setTimeout(res, ms));
      for (let i = 0; i < 10; i++) {
        this.loaderValue += 10;
        await timer(200); // then the created Promise can be awaited
      }
      this.setDoneTest("Done");
    },
    reloadPage() {
      window.location.reload();
    },
    async saveResult() { 
      let currentdate = new Date();
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (!this.submitLoading) { // Prevent multiple submissions
        this.submitLoading = true;
        let trimmedName = this.name.replace(/\s+/g, "");
        const languagePrefix = this.$route.path.startsWith("/ro") ? "ro/" : "";

        this.successURL =
          `https://demo.iqlivetest.com/${languagePrefix}result/` +
          trimmedName +
          "/" +
          this.smsRandomId;
        axios
          .post(
            "https://backend.iqlivetest.com/api/result",
            {
              doc_id: this.smsRandomId,
              name: this.name,
              email: this.email,
              iq_score: this.iqScore,
              percentage: this.percentage,
              total_items: this.randomQuestions.length,
              correct_answers: this.correctAnswer,
              sms_unique_id: this.smsRandomId,
              clickid: this.clickid,
              dlr_status: 0,
              language: "en",
              date:
                monthNames[currentdate.getMonth()] +
                " " +
                currentdate.getDate() +
                " " +
                currentdate.getFullYear(),
            },
            {
              headers: { // Set the headers to allow CORS
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                "Access-Control-Allow-Headers":
                  "Origin, X-Requested-With, Content-Type, Accept, Authorization",
              },
            }
          )
          .then(() => {
            this.$refs.checkoutRef.redirectToCheckout();
          })
          .catch(() => {
          });
      }
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      if (this.email != "") {
        this.saveResult();
      } else {
        this.showEmailError = true;
      }
    },
  },
};
</script>
